<template>
  <div id="chats">
    <div
      class="goChat"
      @click="isShows()"
      :style="style.gochat"
      v-if="style.chatbtn == 'btns1'"
    >
      <!-- <img src="../assets/images/goChat.gif" alt="" /> -->
      <div class="xiaoxi" :style="style.xiaoxi">
        <i class="el-icon-s-comment"></i>
        <img class="gif" src="../assets/images/reddian.gif" alt="" />
      </div>
      <div :style="style.textStyle">
        {{ style.text }}<i class="el-icon-arrow-up"></i>
      </div>
    </div>
    <div
      class="goChat2"
      @click="isShows()"
      :style="style.gochat2"
      v-else-if="style.chatbtn == 'btns2'"
    >
      <div class="xiaoxi" :style="style.xiaoxi">
        <i class="el-icon-s-comment"></i>
        <img class="gif" src="../assets/images/reddian.gif" alt="" />
      </div>
      <div :style="style.textStyle2">{{ style.text }}</div>
    </div>
    <div
      class="goChat3"
      v-else-if="style.chatbtn == 'btns3'"
      @click="isShows()"
    >
      <i class="el-icon-chat-dot-round icon"></i>
      <img class="gif" src="../assets/images/reddian.gif" alt="" />
    </div>
    <div class="big" v-show="isShow">
      <div class="close" @click="close">
        <img src="../assets/images/close.png" alt="" />
      </div>
      <h2>在线客服</h2>
      <p>选择需要咨询的业务</p>
      <ul class="business">
        <li v-for="(val, index) in list" @click="gochat(val)" :key="index">
          <a>{{ val.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  ykDl,
  fpKf,
  bindId,
  getIp,
  joinRoom,
  sendMsg,
  saveText,
  getChatMsg,
  saveImg,
  getAutoreply,
  getVisitorList,
  getSiteTags,
  saveImgs,
  getSiteStyle,
} from "../api/xkw";
export default {
  name: "MyproIndexChat",

  data() {
    return {
      isShow: false,
      list: [
        { title: "简历模板获取" },
        { title: "职业/岗位规划" },
        { title: "企业入驻" },
        { title: "如何与我们联系" },
        { title: "求职兼职群" },
        { title: "兼职预约" },
        { title: "学历提升" },
        { title: "技能提升" },
      ],
      url: "", //当前站点url
      ws: null,
      // wsUrl: "ws://47.114.2.68:8282",
      wsUrl: "wss://chat.xuekao123.com/wss",
      client_id: "",
      user: {},
      ip: {},
      kefu: {},
      style: {
        gochat: {
          width: "220px",
          height: "50px",
          background: "#0362D2",
        },
        gochat2: {
          width: "50px",
          height: "230px",
          background: "#0362D2",
        },
        gochat3: {
          background: "#0362D2",
        },
        xiaoxi: {
          width: "50px",
          height: "50px",
          background: "#0000003b",
        },
        text: "和我们在线交谈！",
        textStyle: {
          width: "80%",
          fontSize: "16px",
          fontFamily: "Source Han Serif CN",
          fontWeight: "500",
          color: "#FFFFFF",
          textAlign: "center",
          lineHeight: "50px",
        },
        textStyle2: {
          margin: "auto",
          width: "40%",
          fontSize: "16px",
          fontFamily: "Source Han Serif CN",
          fontWeight: "500",
          color: "#FFFFFF",
          textAlign: "center",
          lineHeight: "20px",
          "line-break": "anywhere",
          "margin-top": "9px",
        },
        chatbtn: "btns1",
      },
      isRefresh: false,
    };
  },
  async created() {
    this.url = window.location.host;
    // this.url = "www.hnjkrc.com";

    let getStyleData = {
      url: this.url,
      type: 0,
    };
    let getStyle = await getSiteStyle(getStyleData);
    if (getStyle.data.code == 200) {
      let msg = getStyle.data.data;
      sessionStorage.setItem("siteStyle", JSON.stringify(msg));
      this.style.text = msg.themetxt.onlinetxt;
      // console.log(msg);
      this.style.gochat.background = msg.themetxt.maincolor;
      this.style.gochat2.background = msg.themetxt.maincolor;
      this.style.gochat3.background = msg.themetxt.maincolor;
      this.style.chatbtn = msg.chatbtn.btnstyle;
      if (msg.consultationmenu.fastconsult.length) {
        this.list = msg.consultationmenu.fastconsult;
      }
    }
  },
  async mounted() {
    this.bus.$on("show2", (msg) => {
      this.isRefresh = msg;
    });
    let ip = await getIp();
    this.ip = ip.data.data;
    sessionStorage.setItem("ip", JSON.stringify(this.ip));
  },

  methods: {
    async isShows() {
      this.webSocketInit();
      this.isShow = true;
      // let a = JSON.parse(sessionStorage.getItem("user"));
      // let kf = JSON.parse(sessionStorage.getItem("kfId"));
      // if (!a) {
      //   this.isShow = true;
      // } else {
      //   if (!kf) {
      //     // this.$router.push({
      //     //   name: "saveLeaveMessage",
      //     // });
      //     setTimeout(async () => {
      //       let service = JSON.parse(sessionStorage.getItem("kfId"));
      //       let data = {};
      //       if (service) {
      //         data = {
      //           client_id: this.client_id,

      //           message: {
      //             type: "bind",
      //             myid: a.id,
      //             url: this.url,
      //             ip: this.ip.ip,
      //             ip_area: this.ip.ip_area,
      //             admin_id: service.data,
      //           },
      //         };
      //       } else {
      //         data = {
      //           client_id: this.client_id,
      //           message: {
      //             type: "bind",
      //             myid: a.id,
      //             url: this.url,
      //             ip: this.ip.ip,
      //             ip_area: this.ip.ip_area,
      //           },
      //         };
      //       }

      //       // console.log("绑定uid");
      //       let as = await bindId(data);
      //       if (as.data.code == 200) {
      //         let datas = {
      //           url: this.url,
      //           toid: a.id,
      //         };
      //         let res = await fpKf(datas);
      //         if (res.data.code == 203) {
      //           this.isShow = false;
      //           this.$router.push("/saveLeaveMessage");
      //         } else {
      //           this.isShow = false;
      //           this.$router.push("/indexService");
      //         }
      //       }
      //     }, 50);

      //     // this.bindId();
      //   } else {
      //     this.bus.$emit("isShows", true);
      //     if (this.isRefresh == false) {
      //       this.$router.push({
      //         name: "indexService",
      //       });
      //     }
      //   }
      // }
    },
    close() {
      this.isShow = false;
    },
    async gochat(val) {
      let that = this;
      let userData = JSON.parse(sessionStorage.getItem("user"));
      let kefuData = JSON.parse(sessionStorage.getItem("kfId"));
      if (userData && kefuData) {
        this.isShow = false;
        this.$router.push({
          name: "indexService",
          params: {
            data: val,
          },
        });
      } else {
        // var myId = ""; //用户id
        // 假定 随机游客ID
        // myId = parseInt(Math.random() * (9999 - 1000 + 1) + 1000);
        // let url = "baidu.com";

        let datass = {
          fromUrl: this.url,
          url: this.url,
        };

        let user = await ykDl(datass);
        if (user.data.code == 200) {
          that.user = user.data.data;
          sessionStorage.setItem("user", JSON.stringify(user.data.data));

          let service = JSON.parse(sessionStorage.getItem("kfId"));
          let datas = {};
          if (service) {
            datas = {
              client_id: this.client_id,

              message: {
                type: "bind",
                myid: this.user.id,
                url: this.url,
                ip: this.ip.ip,
                ip_area: this.ip.ip_area,
                admin_id: service.data,
              },
            };
          } else {
            datas = {
              client_id: this.client_id,
              message: {
                type: "bind",
                myid: this.user.id,
                url: this.url,
                ip: this.ip.ip,
                ip_area: this.ip.ip_area,
              },
            };
          }

          let a = await bindId(datas);

          let data = {
            url: this.url,
            toid: user.data.data.id,
          };
          let res = await fpKf(data);
          // console.log(res, "分配客服");

          if (res.data.code == 203) {
            // alert("暂无客服在线，将转到留言板");

            this.$router.push("/saveLeaveMessage");
            this.isShow = false;
          } else {
            // this.kfRoom();
            // this.kefu = res.data.data;
            // sessionStorage.setItem("kfId", JSON.stringify(res.data.data));
            this.isShow = false;
            this.$router.push({
              name: "indexService",
              params: {
                data: val,
              },
            });
          }
        }
      }
    },
    // 链接开始聊天
    webSocketInit() {
      let that = this;
      this.ws = new WebSocket(this.wsUrl);
      // 首次链接
      this.ws.onopen = function (e) {
        // console.log(e, "链接成功");
        that.heartbeat();
      };
      // 收到消息
      this.ws.onmessage = function (e) {
        let data = JSON.parse(e.data);
        // console.log(data, "收到的信息99999999");
        // init为初始化
        if (data.type == "init") {
          that.client_id = data.client_id;
          if (that.user && that.kefu) {
            // that.bindId();
          }
        }
      };
    },
    // 发送心跳
    heartbeat() {
      setInterval(() => {
        this.ws.send('{ "type": "ping" }');
      }, 3000);
    },
    // 绑定uid
    async bindId(cid) {
      let service = JSON.parse(sessionStorage.getItem("kfId"));
      let data = {};
      if (service) {
        data = {
          client_id: this.client_id,

          message: {
            type: "bind",
            myid: this.user.id,
            url: this.url,
            ip: this.ip.ip,
            ip_area: this.ip.ip_area,
            admin_id: service.data,
          },
        };
      } else {
        data = {
          client_id: this.client_id,
          message: {
            type: "bind",
            myid: this.user.id,
            url: this.url,
            ip: this.ip.ip,
            ip_area: this.ip.ip_area,
          },
        };
      }

      // console.log("绑定uid");
      let a = await bindId(data);
      // console.log(a, "绑定uid");
      this.kfRoom(cid);
    },
    // 分配客服拉进房间
    async kfRoom(cid) {
      let roomData = {
        client_id: this.client_id,
        message: {
          type: "join",
          toid: this.kefu.data,
          myid: this.user.id,
        },
      };

      let a = await joinRoom(roomData);
    },
  },
};
</script>

<style lang="scss" scoped>
#chats {
  z-index: 999999;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}
.goChat {
  cursor: pointer;
}
.big {
  position: relative;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 450px;
  height: 344px;
  margin: auto;
  padding-left: 18px;
  padding-top: 32px;
  background-color: #fff;
  box-shadow: 0px 0px 18px 0px rgba(203, 203, 203, 0.33);
  background: url("../assets/images/kefu.png") no-repeat;
  // display: none;
}

.business {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

.business > li {
  width: 130px;
  height: 50px;
  margin-right: 14px;
  margin-bottom: 23px;
  line-height: 50px;
  text-align: center;
  background: #dee7f7;
  border-radius: 10px;
}

.big > h2 {
  font-size: 24px;
  font-family: Source Han Serif CN;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
}

.big > p {
  margin-top: 5px;
  font-size: 14px;
  font-family: Source Han Serif CN;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
  opacity: 0.8;
}

.business > li > a {
  font-size: 16px;
  font-family: Source Han Serif CN;
  font-weight: 500;
  color: #2867ce;
  line-height: 24px;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.goChat {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  cursor: pointer;
}
.goChat2 {
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}
.goChat3 {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #2867ce;
  text-align: center;

  .icon {
    font-size: 25px;
    color: #fff;
    line-height: 60px;
  }
  .gif {
    position: absolute;
    top: 16px;
    left: 36px;
  }
}
.xiaoxi {
  position: relative;
  font-size: 28px;
  color: white;
  text-align: center;
  line-height: 50px;
  .gif {
    position: absolute;
    top: 12px;
    left: 11px;
  }
}
</style>