import Vue from 'vue'
import VueRouter from 'vue-router'
import indexChat from '../views/indexShow.vue'
import indexService from '../views/indexService.vue'
import saveLeaveMessage from '../views/saveLeaveMessage.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'chats',
  component: indexChat,
  redirect: "service",
}, {
  path: '/service',
  name: 'chats',
  component: indexChat,
}, {
  path: '/indexService',
  name: 'indexService',
  component: indexService,
}, {
  path: '/saveLeaveMessage',
  name: 'saveLeaveMessage',
  component: saveLeaveMessage,
}]

const router = new VueRouter({
  routes,
  mode: 'abstract'
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router