import {
    request3
} from "./index";

//游客登录
export function ykDl(data) {
    return request3({
        url: '/api/users/userLogin',
        method: 'post',
        data: data
    })
}

//分配客服
export function fpKf(data) {
    return request3({
        url: '/api/Chat/assignCustomerService',
        method: 'post',
        data: data
    })
}

//进入房间
export function joinRoom(data) {
    return request3({
        url: '/api/WsApi/joinGroup',
        method: 'post',
        data: data
    })
}

//获取自动回复
export function getAutoreply(data) {
    return request3({
        url: '/api/chat/getAutoreply',
        method: 'post',
        data: data
    })
}


//绑定id
export function bindId(data) {
    return request3({
        url: '/api/WsApi/bindUid',
        method: 'post',
        data: data
    })
}

//发送消息
export function sendMsg(data) {
    return request3({
        url: '/api/WsApi/sendMsg',
        method: 'post',
        data: data
    })
}

// 发送消息之前先把数据传进数据库
// export function saveText(data) {
//     return request3({
//         url: '/api/Savemsg/saveText',
//         method: 'post',
//         data: data
//     })
// }

//获取敏感词
export function judgeBadWords(data) {
    return request3({
        url: '/api/chat/getSensitiveWords',
        method: 'post',
        data: data
    })
}

// 获取ip地址
export function getIp() {
    return request3({
        url: '/api/chat/getIp',
        method: 'get'
    })
}

// 获取聊天记录
export function getChatMsg(data) {
    return request3({
        url: '/api/Savemsg/getChatMsg',
        method: 'post',
        data: data
    })
}

// 发送图片
export function saveImg(data) {
    return request3({
        url: '/api/Savemsg/saveImg',
        method: 'post',
        data: data
    })
}

// 拉取聊天记录
export function getVisitorList(data) {
    return request3({
        url: '/api/chat_message/Chat/getVisitorList',
        method: 'post',
        data: data
    })
}

// 快捷回复
export function getSiteTags(data) {
    return request3({
        url: '/api/site/setting/getSiteTags',
        method: 'post',
        data: data
    })
}
// 存图片
export function saveImgs(data) {
    return request3({
        url: '/api/chat/saveImg',
        method: 'post',
        data: data
    })
}
// 留言
export function saveLeaveMessage(data) {
    return request3({
        url: '/api/Chat/saveLeaveMessage',
        method: 'post',
        data: data
    })
}
// 获取风格
export function getSiteStyle(data) {
    return request3({
        url: '/api/style/setting/getSiteStyle',
        method: 'post',
        data: data
    })
}

// 判断客服是否在线
export function isUidOnline(data) {
    return request3({
        url: '/api/wsapi/isUidOnline',
        method: 'post',
        data: data
    })
}

// 留言
export function getUrlInfos(data) {
    return request3({
        url: '/api/site/setting/getUrlInfos',
        method: 'post',
        data: data
    })
}