<template>
  <div>
    <ul>
      <li
        class="emjoi3"
        v-for="(item, index) in emjoi"
        :key="index"
        @click="selectEmjoi(item.icon)"
      >
        {{ item.icon }}
      </li>
    </ul>
  </div>
</template>

<script>
import emjoi from "../database/emoji";
export default {
  name: "ChatEmjoi",

  data() {
    return {
      emjoi: emjoi[0].children,
    };
  },

  mounted() {
    // console.log(emjoi);
  },

  methods: {
    selectEmjoi(e) {
      //   console.log(e);
      this.bus.$emit("emjois", e);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  max-width: 400px;
  max-height: 200px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
  overflow-y: auto;
  li {
    padding: 4px;
  }
}
ul::-webkit-scrollbar {
  width: 0 !important;
}
ul::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>