<template>
  <div id="save">
    <div class="chat">
      <div class="top">
        <!-- <img class="avatar" :src="avatarImg" alt="" /> -->
        <h2>留言板</h2>
        <div @click="suoxiao">
          <div></div>
        </div>
      </div>
      <div class="message">
        <p>
          当前人工客服繁忙，建议您留下您的联系方式，工作人员会第一时间联系您。
        </p>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="name">
            <el-input
              v-model="ruleForm.name"
              prefix-icon="el-icon-user"
              placeholder="姓名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input
              prefix-icon="el-icon-phone-outline"
              v-model="ruleForm.phone"
              placeholder="电话"
            ></el-input>
          </el-form-item>
          <el-form-item prop="message" class="mes">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="ruleForm.message"
              prefix-icon="el-icon-phone-outline"
              class="sd2"
            >
            </el-input>
            <i class="as el-icon-tickets"></i>
          </el-form-item>
          <el-form-item class="btnClick" style="text-align: end">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { saveLeaveMessage, getUrlInfos } from "../api/xkw";
import encryption from "../utils/encryption";
import Axios from "axios";
export default {
  name: "MyproSaveLeaveMessage",

  data() {
    // 验证手机号的规则
    var checkMobile = (rule, value, callback) => {
      // 验证手机号的正则表达式
      const regMobile = /^1[3456789]\d{9}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      callback(new Error("请输入合法的手机号"));
    };
    return {
      ruleForm: {
        visitor_id: "",
        name: "",
        phone: "",
        message: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "电话不能为空", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
        message: [
          { required: true, message: "请填写您的留言", trigger: "blur" },
        ],
      },
      siteInfos: JSON.parse(sessionStorage.getItem("siteInfo")),
    };
  },

  async mounted() {
    // this.url = window.location.host;
  },
  created() {
    // this.ruleForm.visitor_id = JSON.parse(sessionStorage.getItem("user")).id;
  },
  methods: {
    async submitForm(formName) {
      // this.$refs[formName].validate(async (valid) => {
      //   if (valid) {
      //     // console.log(valid);
      //     // console.log(this.ruleForm);
      //     let a = await saveLeaveMessage(this.ruleForm);
      //     // console.log(a);
      //     if (a.data.code == 200) {
      //       this.$message({
      //         message: "提交成功,请保持手机通畅，方便我们日后联系您！",
      //         type: "success",
      //       });
      //       setTimeout(() => {
      //         this.$router.push("/");
      //       }, 1000);
      //     }
      //   } else {
      //     // console.log("error submit!!");
      //     return false;
      //   }
      //   // console.log(formName);
      // });
      let dt1 = encryption({
        token: "4fc3393f1f3d76f51f1dbdb4d46c557f",
        name: this.ruleForm.name,
        phone: this.ruleForm.phone,
        sourceId: this.siteInfos.source_id,
        spreadId: this.siteInfos.spread_id,
        xkwFirstProjectId: this.siteInfos.firstproject_id,
        xkwSearchEnginId: 101,
        xkwHobbyId: this.siteInfos.hobby_id,
        isAutoPrt: 0,
        yunuserId: 23,
        companyId: this.siteInfos.company_id,
        chatRecord:
          "姓名:" +
          this.ruleForm.name +
          "\n手机号：" +
          this.ruleForm.phone +
          "\n留言内容：" +
          this.ruleForm.message,
      });
      Axios({
        method: "post",
        url: "https://tk.360xkw.com/XKW/yunStuInfo/addStuInfoXKWNoLogin.do",
        headers: {
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        data: {
          data: dt1.data,
          sk: dt1.sk,
        },
      })
        .then((result) => {
          if (result.data.S == "1") {
            alert(
              "录入成功。您好!您的留言我们已经收到，24小时内会安排有关专业人员与您取得联系，在此期间，请您保持电话畅通。感谢您的关注与支持祝您生活偷快~"
            );
            // this.$message({
            //   message: "提交成功,请保持手机通畅，方便我们日后联系您！",
            //   type: "success",
            // });

            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          } else {
            this.$message({
              message: "系统异常",
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    suoxiao() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
#save {
  position: fixed;
  bottom: 20px;
  right: 10px;
  box-shadow: 0px 0px 18px 0px rgba(203, 203, 203, 0.33);
  z-index: 999999;
  .chat {
    .top {
      width: 350px;
      height: 60px;
      padding: 0 18px;
      background: #0362d2;
      border-radius: 10px 10px 0 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        // margin-left: 18px;
        font-size: 16px;
        font-family: Source Han Serif CN;
        font-weight: bold;
        color: #ffffff;
        line-height: 24px;
      }
      div {
        height: 100%;
        display: flex;
        align-items: center;
        div {
          width: 20px;
          height: 2px;
          background: #ffffff;
        }
      }
    }
    .message {
      width: 350px;
      height: 390px;
      overflow: hidden;
      padding: 0 25px;
      background: #ffffff;
      box-sizing: border-box;
      p {
        font-size: 16px;
        margin: 18px 0 18px 0;
        font-family: Source Han Serif CN;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
    }
    .mes {
      position: relative;
      .as {
        position: absolute;
        top: 9.5px;
        left: 9px;
        color: #999;
      }
    }
  }
}
</style>