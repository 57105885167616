import Axios from "axios";

// export default requests

export function request3(config) {
    // 创建axios实例
    const instance = Axios.create({
        baseURL: "https://chat.xuekao123.com",
        timeout: 50000,
    })
    instance.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
    instance.defaults.headers["X-Requested-With"] = "xmlhttprequest";
    // 发送真正的网络请求
    return instance(config)

}